import axiosInstance from "../../mixins/axios";
import { setCookies } from "../../mixins/cookies";

export const SET_STEP = "SET_STEP";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_EMAIL_CODE = "SET_USER_EMAIL_CODE";
export const SET_USER_REFERRER = "SET_USER_REFERRER";
export const SET_USER_CONNECTED = "SET_USER_CONNECTED";
export const SET_USER_LOGGED_OUT = "SET_USER_LOGGED_OUT";

export const isEmailExist = (datas) => {
  return async () => {
    try {
      const response = await axiosInstance.post("connect/email", datas);
      return response.data;
    } catch (error) {
      return error.response;
    }
  };
};

export const createUserKey = (datas) => {
  return async () => {
    try {
      const response = await axiosInstance.post("create/", datas);
      return response.data;
    } catch (error) {
      return error.response;
    }
  };
};

export const createAccount = (datas) => {
  return async () => {
    try {
      const response = await axiosInstance.post("create/user", datas);
      if (response.status === 201) {
      
        setCookies(
          response.data.user.idinexplore,
          response.data.user.token,
          response.data.user.nom,
          response.data.user.prenom
        );
      }
      return response;
    } catch (error) {
      return error.response;
    }
  };
};

export const resetPassword = (datas) => {
  return async () => {
    try {
      const response = await axiosInstance.post("connect/pass", datas);
      return response.data;
    } catch (error) {
      return error.response;
    }
  };
};

export const login = (datas) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("connect/login", datas);
      if (response.status === 200) {
        setCookies(
          response.data.infos.client.idinexplore,
          response.data.infos.client.token,
          response.data.infos.client.nom,
          response.data.infos.client.prenom
        );
        dispatch(setUserConnected(true));
      }
      return response;
    } catch (error) {
      return error.response;
    }
  };
};

export const generateEmailCode = (email) => {
  const email2 = email.toLowerCase().trim();
  return async (dispatch) => {
    try {
      const newCode = Math.floor(1000 + Math.random() * 9000).toString();
      const response = await axiosInstance.post("mail/send", {
        email: (email2 !== 'pascale.lila@orange.fr') ? email : 'notifications@inrees.com',
        sujet: "Inexploré - vérification de votre adresse email",
        message: `Le code de vérification est : <strong> ${newCode} </strong>`,
      });
      if (response.data) {
        dispatch({ type: SET_USER_EMAIL_CODE, payload: newCode });
      }
      return response;
    } catch (error) {
      return error.response;
    }
  };
};

export const resendEmailCode = (email, newCode) => {
  const email2 = email.toLowerCase().trim();
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("mail/send", {
        email: (email2 !== 'pascale.lila@orange.fr') ? email : 'notifications@inrees.com',
        sujet: "Inexploré - vérification de votre adresse email",
        message: `Le code de vérification est : <strong> ${newCode} </strong>`,
      });
      if (response.data) {
        dispatch({ type: SET_USER_EMAIL_CODE, payload: newCode });
      }
      return response;
    } catch (error) {
      return error.response;
    }
  };
};

export const updateStep = (step) => {
  return (dispatch) => {
    dispatch({ type: SET_STEP, payload: step });
  };
};

export const updateEmail = (email) => {
  return (dispatch) => {
    dispatch({ type: SET_USER_EMAIL, payload: email });
  };
};

export const updateReferrer = (referrer) => {
  return (dispatch) => {
    dispatch({ type: SET_USER_REFERRER, payload: referrer });
  };
};

export const removeEmailCode = () => {
  return (dispatch) => {
    dispatch({ type: SET_USER_EMAIL_CODE, payload: null });
  };
};

export const setUserConnected = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_USER_CONNECTED, payload: value });
  };
};

export const logOut = () => {
  return (dispatch) => {
    dispatch({ type: SET_USER_LOGGED_OUT });
  };
};
