import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import * as authActions from "../../../store/actions/authActions";
import * as EmailValidator from "email-validator";
import Errors from "../../layout/Errors";
import TitleBlock from "../TitleBlock";
import Loader from "../loader";
import TextInput from "./TextInput";
import MyinexploreLogo from "../../../assets/images/svg/my-inexplore-logo.svg";
import Cookies from "universal-cookie";

export default function AuthForm(props) {
  const passwordRef = React.useRef(null)
  const [email, setEmail] = useState(props.userEmail);
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    status: false,
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const authReducer = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitEmail = (e, params) => {
    e.preventDefault();
    e.stopPropagation();
    setError({status: false});
    if (email === "") {
      setError({
        status: true,
        message: "Veuillez renseigner votre adresse email",
      });
    } else {
      const isEmailValid = EmailValidator.validate(email);
      if (!isEmailValid) {
        setError({
          status: true,
          message: "Veuillez renseigner une adresse email valide",
        });
      } else {
        setLoading(true);
        isEmailExist(email, params);
      }
    }
  };

  const generateVerificationCode = async (email) => {
    try {
      const response = await dispatch(authActions.generateEmailCode(email));
      if (response.data) {
        navigate("/register");
        setLoading(false);
      }
    } catch (error) {
      setError({
        status: true,
        message: "Une erreur est survenue. Veuillez réessayer ultérieurement",
      });
    }
  };

  const isEmailExist = async (email, params) => {
    try {
      const response = await dispatch(
          authActions.isEmailExist({email: email})
      );
      if (response.infos.tab.exist === 0) {
        dispatch(authActions.updateEmail(email));
        generateVerificationCode(email);
      } else {
        if (params) {
          setError({
            status: true,
            message: "Un compte est déjà associé à cette adresse email",
          });
          setLoading(false);
        } else {
          dispatch(authActions.updateStep(1));
          dispatch(authActions.updateEmail(email));
          setLoading(false);
          setTimeout(() => {
            passwordRef?.current?.focus();
          })
        }
      }
    } catch (error) {
      setError({
        status: true,
        message: "Une erreur est survenue, veuillez réessayer ultérieurement",
      });
      setLoading(false);
    }
  };

  const onSubmitPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setError({
      status: false,
    });
    if (password === "") {
      setError({
        status: true,
        message: "Veuillez renseigner votre mot de passe",
      });
    } else {
      setLoading(true);
      login();
    }
  };

  const handleChangeEmail = (value) => {
    setEmail(value);
    setError({status: false, message: ""});
  };

  const handleChangePassword = (value) => {
    setPassword(value);
    setError({status: false, message: ""});
  };

  const login = async () => {
    try {
      const cookies = new Cookies();
      const affiliation = cookies.get("affiliation") || null;
      const response = await dispatch(
          authActions.login({email: authReducer.email, password: password, affiliation})
      );
      if (response.status !== 200) {
        if (response.data.message === "Email ou mot de passe incorrect") {
          setError({
            status: true,
            message: "Email ou mot de passe incorrect",
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setError({
        status: true,
        message: "Une erreur est survenue. Veuillez réessayer ultérieurement",
      });
      setLoading(false);
    }
  };

  return (
      <div>
        <TitleBlock
            logo={true}
            source={MyinexploreLogo}
            h1={
              authReducer.step === 0
                  ? "Connectez-vous ou créez un compte"
                  : "Mot de passe"
            }
            h2={
              authReducer.step === 0
                  ? ""
                  : "Merci de renseigner votre mot de passe pour vous connecter."
            }
        />

        {error.status && (
            <Errors
                className="error-authform"
                backgroundColor={"rgba(233, 75, 50, 0.08)"}
                textColor={"#e94b32"}
                message={error.message}
            />
        )}
        <form
            className="form-container"
            onSubmit={(e) =>
                authReducer.step === 0 ? onSubmitEmail(e) : onSubmitPassword(e)
            }
        >
          <div style={{display: authReducer.step === 0 ? 'initial' : "none"}}>
            <TextInput
                className={
                  !error.status
                      ? "input-floating input-border"
                      : "input-floating input-border-error"
                }
                label="Mon adresse email"
                type="email"
                value={email}
                disabled={false}
                autoFocus={true}
                rightIcon={true}
                requiredMention={false}
                handleChangeParent={handleChangeEmail}
            ></TextInput>
          </div>
          <div style={{display: authReducer.step === 1 ? 'initial' : "none"}}>
            <TextInput
                className={
                  !error.status
                      ? "input-floating input-border"
                      : "input-floating input-border-error"
                }
                label="Mon mot de passe"
                type="password"
                value={password}
                autoFocus={true}
                rightIcon={true}
                disabled={false}
                requiredMention={false}
                handleChangeParent={handleChangePassword}
                passwordRef={passwordRef}
            ></TextInput>
            <button
                type="button"
                className="btn-clear btn-clear-small-grey"
                onClick={() => navigate("/forgot-password")}
            >
              J'ai oublié mon mot de passe
            </button>
          </div>
          <div style={{display: loading ? 'initial' : "none"}} className="loader submit-login">
            <Loader size={15} color="#162a41"/>
          </div>
          <button style={{display: loading ? 'none' : "initial"}} className="btn-gradient submit-login" type="submit">
            <span>{authReducer.step === 0 ? "Suivant" : "Valider"}</span>
          </button>
        </form>
        {!loading && authReducer.step === 0 && (
            <button
                type="button"
                className="btn-clear btn-clear-dark-blue full-width"
                onClick={(e) => onSubmitEmail(e, "register")}
            >
              Créer un compte
            </button>
        )}
      </div>
  );
}
