import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import * as authActions from "../store/actions/authActions";
import SEOMixins from "../components/mixins/SEOMixins";
import AuthHeader from "../components/layout/AuthHeader";
import Errors from "../components/layout/Errors";
import TextInput from "../components/elements/forms/TextInput";
import PhoneInput from "../components/elements/forms/phoneInput";
import VerificationEmailCode from "../components/elements/forms/VerificationEmailCode";
import Loader from "../components/elements/loader";
import Cookies from "universal-cookie";

export default function Register(props) {
  const authReducer = useSelector((state) => state.authReducer);

  /* States declarations */

  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState("undefined");
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState({
    id: 250,
    name: "France",
    code: "fr",
    dialcode: "+33",
    flag: "🇫🇷",
    unicode: "U+1F1EB U+1F1F7",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberInternational, setPhoneNumberInternational] =
    useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptLetter, setAcceptLetter] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    phoneNumber: false,
    password: false,
    confirmPassword: false,
    matchPassword: true,
    submit: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (authReducer.code === null) {
      navigate("/");
    }
  }, [navigate, authReducer.code]);

  /* States handler */

  const handleChangeFirstname = (value) => {
    setFirstname(value);
    setErrors({ ...errors, firstName: false });
  };
  const handleChangeLastname = (value) => {
    setLastName(value);
    setErrors({ ...errors, lastName: false });
  };
  const handleChangePhoneNumber = (value) => {
    setPhoneNumber(value);
    setErrors({ ...errors, phoneNumber: false });
  };
  const handleChangePassword = (value) => {
    setPassword(value);
    setErrors({ ...errors, password: false, matchPassword: true });
  };
  const handleChangeConfirmPassword = (value) => {
    setConfirmPassword(value);
    setErrors({ ...errors, confirmPassword: false, matchPassword: true });
  };
  const handleChangeCountryCode = (value) => {
    setCountryCode(value);
  };
  const handleChangeAcceptLetter = (value) => {
    setAcceptLetter(value);
  };
  const handleChangeCode = (value) => {
    if (value) {
      setTimeout(() => {
        setVerificationCode(value);
      }, 2000);
    } else {
      setVerificationCode(value);
    }
  };

  /* Submit form & validations */

  const dispatch = useDispatch();

  const verifPhoneNumber = () => {
    try {
      const internationalFormat = parsePhoneNumber(
        `${countryCode.dialcode}${phoneNumber}`
      ).formatInternational();
      if (
        !isValidPhoneNumber(internationalFormat, countryCode.code.toUpperCase())
      ) {
        return false;
      } else {
        setPhoneNumberInternational(internationalFormat);
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const submitRegisterForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newErrors = {
      firstName: false,
      lastName: false,
      phoneNumber: false,
      password: false,
      confirmPassword: false,
      matchPassword: true,
      submit: false,
    };
    if (firstName.length < 1) {
      newErrors.firstName = true;
    }
    if (lastName.length < 1) {
      newErrors.lastName = true;
    }
    if (phoneNumber.length < 1) {
      newErrors.phoneNumber = true;
    } else {
      const response = verifPhoneNumber();
      if (!response) {
        newErrors.phoneNumber = true;
      }
    }
    if (password.length < 1) {
      newErrors.password = true;
    } else {
      if (password.length < 5) {
        newErrors.password = true;
      }
    }
    if (confirmPassword.length < 1) {
      newErrors.confirmPassword = true;
    } else {
      if (password !== confirmPassword) {
        newErrors.matchPassword = false;
      }
    }

    setErrors(newErrors);
    if (
      !newErrors.firstName &&
      !newErrors.lastName &&
      !newErrors.password &&
      !newErrors.phoneNumber &&
      !newErrors.confirmPassword &&
      newErrors.matchPassword
    ) {
      setLoading(true);
      createUserKey();
    }
  };

  const createUserKey = async () => {
    try {
      const response = await dispatch(
        authActions.createUserKey({ email: authReducer.email })
      );
      if (response.cle) {
        const cookies = new Cookies();
        const affiliation = cookies.get("affiliation") || null;
        createUserAccount({
          cle: response.cle,
          email: authReducer.email,
          civilite: gender,
          prenom: firstName,
          nom: lastName,
          pass: password,
          portable: phoneNumberInternational,
          letter: acceptLetter,
          affiliation
        });
      }
    } catch (error) {
      setErrors({ ...errors, submit: true });
      setLoading(false);
    }
  };

  const createUserAccount = async (datas) => {
    try {
      const response = await dispatch(authActions.createAccount(datas));
      if (response.status === 201) {
        dispatch(authActions.setUserConnected(true));
        setLoading(false);
        navigate("/");
      }
    } catch (error) {
      setErrors({ ...errors, submit: true });
      setLoading(false);
    }
  };

  return (
    <div className="main main-auth">
      <SEOMixins title="Inexploré - Création de compte" description="Inexploré - Création de compte" />
      {authReducer.code !== verificationCode ? (
        <div className="login-wrapper">
          <AuthHeader />
          <div className="login-container">
            <VerificationEmailCode
              workflow="register"
              handleChangeCodeParent={handleChangeCode}
            />
          </div>
        </div>
      ) : (
        <div className="register-wrapper">
          <AuthHeader matchCode={true} />
          <div className="register-container">
            <form
              className="register-form"
              onSubmit={(e) => submitRegisterForm(e)}
            >
              <h1>Mes informations personnelles</h1>
              <div className="radio-container">
                <div className="radio-item">
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="Madame"
                    checked={gender === "Madame"}
                    onChange={(e) => setGender("Madame")}
                  />
                  <label htmlFor="female">Madame</label>
                </div>
                <div className="radio-item">
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="Monsieur"
                    checked={gender === "Monsieur"}
                    onChange={(e) => setGender("Monsieur")}
                  />
                  <label htmlFor="male">Monsieur</label>
                </div>
                <div className="radio-item">
                  <input
                    type="radio"
                    id="undefined"
                    name="gender"
                    value="undefined"
                    checked={gender === "undefined"}
                    onChange={(e) => setGender("undefined")}
                  />
                  <label htmlFor="undefined">
                    Je ne préfère pas le préciser
                  </label>
                </div>
                {gender.error && (
                  <Errors
                    className="error-authform"
                    backgroundColor={"rgba(233, 75, 50, 0.08)"}
                    textColor={"#e94b32"}
                    message={gender.message}
                  />
                )}
              </div>
              <table>
                <tbody>
                  <tr>
                    <td className="specific">
                      <TextInput
                        className={
                          !errors.firstName
                            ? "input-floating input-border"
                            : "input-floating input-border-error"
                        }
                        label="Mon prénom"
                        type="text"
                        value={firstName}
                        disabled={false}
                        rightIcon={false}
                        requiredMention={true}
                        handleChangeParent={handleChangeFirstname}
                      ></TextInput>
                      {errors.firstName && (
                        <Errors
                          className="error-authform"
                          backgroundColor={"rgba(233, 75, 50, 0.08)"}
                          textColor={"#e94b32"}
                          message="Vous devez renseigner votre prénom"
                        />
                      )}
                    </td>
                    <td>
                      <TextInput
                        className={
                          !errors.lastName
                            ? "input-floating input-border"
                            : "input-floating input-border-error"
                        }
                        label="Mon nom"
                        type="text"
                        value={lastName}
                        disabled={false}
                        rightIcon={false}
                        requiredMention={true}
                        handleChangeParent={handleChangeLastname}
                      ></TextInput>
                      {errors.lastName && (
                        <Errors
                          className="error-authform"
                          backgroundColor={"rgba(233, 75, 50, 0.08)"}
                          textColor={"#e94b32"}
                          message="Vous devez renseigner votre nom"
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="specific">
                      <PhoneInput
                        className={
                          !errors.phoneNumber
                            ? "input-floating input-border"
                            : "input-floating input-border-error"
                        }
                        label="Mon portable"
                        type="tel"
                        countryCode={countryCode}
                        value={phoneNumber}
                        disabled={false}
                        rightIcon={true}
                        requiredMention={true}
                        handleChangeParent={handleChangePhoneNumber}
                        handleChangeCountryCodeParent={handleChangeCountryCode}
                      />
                      {errors.phoneNumber && (
                        <Errors
                          className="error-authform"
                          backgroundColor={"rgba(233, 75, 50, 0.08)"}
                          textColor={"#e94b32"}
                          message={
                            phoneNumber.length < 1
                              ? "Vous devez renseigner votre numéro de téléphone"
                              : "Vous devez renseigner un numéro de téléphone valide"
                          }
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2>Mon compte</h2>
              <table>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <TextInput
                        className={"input-floating input-border"}
                        label="Mon adresse email"
                        type="email"
                        value={authReducer.email}
                        disabled={true}
                        rightIcon={true}
                        requiredMention={true}
                      ></TextInput>
                    </td>
                  </tr>
                  <tr>
                    <td className="specific">
                      <TextInput
                        className={
                          !errors.password
                            ? errors.matchPassword
                              ? "input-floating input-border"
                              : "input-floating input-border-error"
                            : "input-floating input-border-error"
                        }
                        label="Mon mot de passe"
                        type="password"
                        value={password}
                        disabled={false}
                        rightIcon={true}
                        requiredMention={true}
                        handleChangeParent={handleChangePassword}
                      ></TextInput>
                      {errors.password && (
                        <Errors
                          className="error-authform"
                          backgroundColor={"rgba(233, 75, 50, 0.08)"}
                          textColor={"#e94b32"}
                          message={
                            password.length < 1
                              ? "Vous devez renseigner votre mot de passe"
                              : "Votre mot de passe doit contenir au moins 5 caractères"
                          }
                        />
                      )}
                    </td>
                    <td>
                      <TextInput
                        className={
                          !errors.confirmPassword
                            ? errors.matchPassword
                              ? "input-floating input-border"
                              : "input-floating input-border-error"
                            : "input-floating input-border-error"
                        }
                        label="Confirmation du mot de passe"
                        type="password"
                        value={confirmPassword}
                        disabled={false}
                        rightIcon={true}
                        requiredMention={true}
                        handleChangeParent={handleChangeConfirmPassword}
                      ></TextInput>
                      {errors.confirmPassword && (
                        <Errors
                          className="error-authform"
                          backgroundColor={"rgba(233, 75, 50, 0.08)"}
                          textColor={"#e94b32"}
                          message="Vous devez renseigner votre mot de passe"
                        />
                      )}
                    </td>
                  </tr>
                  {!errors.matchPassword && (
                    <tr>
                      <td colSpan={2}>
                        <Errors
                          className="error-authform"
                          backgroundColor={"rgba(233, 75, 50, 0.08)"}
                          textColor={"#e94b32"}
                          message={
                            "Le mot de passe et la confirmation du mot de passe ne correspondent pas"
                          }
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="switch-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => handleChangeAcceptLetter(e.target.checked)}
                    checked={acceptLetter}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="slider-text">
                  Je souhaite recevoir les actualités non-commerciales
                  (articles, podcasts, vidéos...)
                </span>
              </div>
              {errors.submit && (
                <Errors
                  className="error-authform"
                  backgroundColor={"rgba(233, 75, 50, 0.08)"}
                  textColor={"#e94b32"}
                  message={
                    "Une erreur est survenue. Veuillez réessayer ultérieurement"
                  }
                />
              )}
              <div className="buttons-container">
                <div className="buttons-wrapper">
                  {!loading && (
                    <button
                      className="btn-outline-dark-blue cancel-register"
                      type="button"
                      onClick={() => dispatch(authActions.removeEmailCode())}
                    >
                      Annuler
                    </button>
                  )}
                  <button
                    disabled={loading}
                    className="btn-gradient submit-login"
                    type="submit"
                  >
                    {loading ? (
                      <Loader size={12} color="#ffffff" />
                    ) : (
                      <span>"S'inscrire"</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
            <p className="mentions-legales">
              En créant votre compte, vous acceptez que l'INREES utilise vos
              données à caractère personnel à des fins de gestion de votre
              compte personnel conformément à sa{" "}
              <a
                href="https://www.inrees.com/politique-confidentialite"
                target="_blank"
                rel="noreferrer"
              >
                politique de confidentialité.
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
